<template>

<div class="clock" :class="{'is-returned': loan.status === $constants.checkout.loan.status.returned}">

	<app-icon v-if="loan.status === $constants.checkout.loan.status.active" icon="clock" class="clock-icon" />
	<app-icon v-if="loan.status === $constants.checkout.loan.status.returned" icon="tick" class="clock-icon" />

	<div class="clock-info"> 

		<div class="clock-info-hours" v-if="loan.status === $constants.checkout.loan.status.active">Less than {{ hours }} hour<template v-if="hours !== 1">s</template> ago</div>
		<div class="clock-info-hours" v-if="loan.status === $constants.checkout.loan.status.returned">Returned after less than {{ hours }} hour<template v-if="hours !== 1">s</template></div>
		<div class="clock-info-time">{{ time }}</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['loan'],

	computed: {

		hours: function() {

			return Math.ceil((this.$moment().utc().unix() - this.loan.date.out) / 3600)

		},

		time: function() {

			return this.$moment.unix(this.loan.date.out).format('h:mma, Do MMMM YYYY')

		}

	}

}

</script>

<style scoped>

.clock {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 10px;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	margin-bottom: 10px;
}

.clock.is-returned {
	background-color: #4faa80;
	color: #fff;
}

.clock-icon {
	font-size: 32px;
	color: #4d5b6e;
	margin-right: 10px;
	width: 40px;
}

.clock.is-returned .clock-icon {
	color: #fff;
}

.clock-info-hours {
	font-weight: 500;
	font-size: 16px;
}

.clock-info-time {
	font-weight: 300;
	font-size: 12px;
	margin-top: 2px;
}

</style>
